<template>
    <div class="comboPage">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="filtrate">
                <div class="line">
                    <div class="kindName">套餐类型</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':spaceTypeValue==item.dictValue}" v-for="(item,i) in spaceTypeList" :key="i" @click="clickSpaceType(item)">{{item.dictLabel}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="kindName">装修风格</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':styleValue==item.dictValue}" v-for="(item,i) in styleList" :key="i" @click="clickStyle(item)">{{item.dictLabel}}</div>
                    </div>
                </div>
                <div class="line">
                    <div class="kindName">价格区间</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':priceValue==item.dictValue}" v-for="(item,i) in priceList" :key="i" @click="clickPrice(item)">{{item.dictLabel}}</div>
                    </div>
                </div>
            </div>
            <div class="comboShow">
                <div class="item" v-for="(item,i) in comboList" :key="i" @click="toDetail(item)">
                    <!-- <img src="../assets/imgs/place43.png"/> -->
                    <img :src="item.coverList[0]?item.coverList[0]:require('../assets/imgs/place43.png')"/>
                    <div class="name">{{item.goodsPackageName}}</div>
                    <!-- <div class="price">￥{{item.totalPrice}}</div> -->
                    <div class="price">{{item.subtitle}}</div>
                </div>
                <div class="empty" v-show="comboList.length==0">
                    <img src="../assets/imgs/goodsEmpty.png" />
                    <div>店家正在努力上新中~</div>
                </div>
            </div>

            <div class="pagi">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="totalNum">
                </el-pagination>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getPackageList_pick,getDictTypeList} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            currentPage:1,
            totalNum:0,
            pageSize:12,
            comboList:[],
            spaceTypeList:[],//空间类型
            spaceTypeValue:"",
            styleList:[],//风格
            styleValue:"",
            priceList:[],//价格区间
            priceValue:"",
        }
    },
    methods: {
        toDetail(item){
            this.$router.push({
                path:"/comboDetail",
                query:{goodsPackageId:item.goodsPackageId}
            })
        },
        handleSizeChange(){},
        handleCurrentChange(){},
        // 查询套餐列表
        gainComboList(){
            getPackageList_pick({
                pageNum:this.currentPage,
                pageSize:this.pageSize,
                priceRange:this.priceValue,
                spaceType:this.spaceTypeValue,
                style:this.styleValue,
            }).then(res=>{
                // console.log("套餐列表",res);
                if(res.code==200){
                    res.data.rows.map(item=>{
                        item.coverList=item.coverStrs.split(",")
                    })
                    this.comboList=res.data.rows;
                    this.totalNum=Number(res.data.total);
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 查询字典数据
        gainDictList(){
            getDictTypeList({
                dictType:"SpaceType"
            }).then(res=>{
                // console.log("空间类型",res);
                if(res.code==200){
                    this.spaceTypeList=res.data;
                    // this.spaceTypeValue=this.spaceTypeList[0].dictValue;
                }
            })
            getDictTypeList({
                dictType:"GoodsStyle"
            }).then(res=>{
                // console.log("风格",res);
                if(res.code==200){
                    this.styleList=res.data.splice(0,10);
                    // this.styleValue=this.styleList[0].dictValue;
                }
            })
            getDictTypeList({
                dictType:"PriceRange"
            }).then(res=>{
                // console.log("价格区间",res);
                if(res.code==200){
                    this.priceList=res.data;
                    // this.priceValue=this.priceList[0].dictValue;
                }
            })
        },
        // 点击空间类型
        clickSpaceType(item){
            if(this.spaceTypeValue==item.dictValue){
                this.spaceTypeValue=""
            }else{
                this.spaceTypeValue=item.dictValue;
            }
            this.gainComboList()
        },
        // 点击风格
        clickStyle(item){
            if(this.styleValue==item.dictValue){
                this.styleValue=""
            }else{
                this.styleValue=item.dictValue;
            }
            this.gainComboList();
        },
        // 点击价格区间
        clickPrice(item){
            if(this.priceValue==item.dictValue){
                this.priceValue=""
            }else{
                this.priceValue=item.dictValue;
            }
            this.gainComboList();
        }
    },
    created() {
        this.gainComboList();
        this.gainDictList();
    },
}
</script>

<style lang="less" scoped>
.comboPage{
    .filtrate{
        margin-top: 50px;
        .line{
            padding: 11px 22px;
            padding-left: 0;
            border-bottom: 1px solid rgba(237, 237, 237, 1);
            display: flex;
            align-items: center;
            .kindName{
                width: 121px;
                box-sizing: border-box;
                font-size: 14px;
                font-weight: 400;
                color: #333
            }
            &:last-child{
                border-bottom: none;
            }
            .subs{
                flex: 1;
                height: 100%;;
                display: flex;
                align-items: center;
                .item{
                    padding: 0 10px;
                    height: 25px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(128, 128, 128, 1);
                    margin-right: 20px;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        background: rgba(37, 126, 93, 1);
                        color: rgba(255, 255, 255, 1);
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .comboShow{
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        .comboItem{
            width: 100%;
            margin-bottom: 90px;
            &:last-child{
                // margin-bottom: 0;
            }
            &.combo1{
                .picArea{
                    background: rgba(222, 204, 180, 1);
                    .smallPic .item{
                        background: rgba(199, 175, 151, 1);
                    }
                }
            }
            &.combo2{
                .picArea{
                    background: rgba(194, 194, 194, 1);
                    .smallPic .item{
                        background: rgba(153, 148, 148, 1);
                    }
                }
            }
            &.combo3{
                .picArea{
                    background: rgba(197, 210, 219, 1);
                    .smallPic .item{
                        background: rgba(113, 137, 166, 1);
                    }
                }
            }
            .picArea{
                width: 100%;
                height: 679px;
                display: flex;
                align-items: center;
                .bigPic{
                    height: 100%;
                    width: 1051px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .smallPic{
                    height: 100%;
                    padding: 30px 0;
                    box-sizing: border-box;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    .item{
                        width: 276px;
                        height: 138px;
                        background: rgba(199, 175, 151, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img{
                            max-width: 80%;
                        }
                    }
                }
            }
            .name{
                margin-top: 30px;
                font-size: 24px;
                font-weight: 500;
                color: #333;
                .comboName{
                    margin-right: 30px;
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(158, 110, 62, 1);

                }
            }
            .comboPrice{
                margin-top: 17px;
                font-size: 24px;
                font-weight: 700;
                color: #333;
                &>span{
                    font-size: 48px;
                    font-weight: 900;
                    color: rgba(212, 48, 48, 1);
                }
            }
        }
        .item{
            width: 700px;
            margin-bottom: 60px;
            cursor: pointer;
            &>img{
                width: 700px;
                height: 363px;
                object-fit: cover;
                // border: 0.5px solid #999;
                // border: 0.5px solid #e5e5e5;
                box-shadow: 0px 0px 4px  rgba(0, 0, 0, 0.25);
                border-radius: 6px;
            }
            .name{
                margin-top: 17px;
                font-size: 18px;
                font-weight: 400;
                color: #333;
            }
            .price{
                margin-top: 15px;
                font-size: 18px;
                font-weight: 400;
                color: #333;
            }
        }
        .empty{
            width: 100%;
            padding: 50px 0;
            display: flex;
            justify-content: center;
        }
        .empty{
            width: 100%;
            padding: 50px 0;
            display: block;
            // justify-content: center;
            text-align: center;
            &>img{
                width: 500px;
                margin-bottom: 10px;
            }
        }
    }
    .pagi{
        margin-top: 130px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: #333;
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>